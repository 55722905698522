import { useStaticQuery, graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"

type Props = {
  description?: string
  lang?: string
  meta?: any[]
  keywords?: string[]
  title?: string
}

const SEO: FunctionComponent<Props> = ({
  description,
  lang,
  meta,
  keywords,
  title,
}) => {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta!)}
      title={title}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <script src="https://cmp.osano.com/16CW1XSJGaBff1dTz/3c4b1403-a3d9-4dbe-bf2a-9810d41058ff/osano.js"></script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-V0VQHMJ565"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-V0VQHMJ565');`}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  keywords: [],
  meta: [],
}

export default SEO
